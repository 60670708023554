.wrapper {
  background-color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  margin-top: 20px;
}

.wrapper > h4 {
  background-color: beige;
  margin: 0;
  padding: 20px;
}